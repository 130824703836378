import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState
} from "react"
import ModalContainer from "../containers/ModalContainer"

interface ModalContainerProps {
  children: ReactNode
  open: boolean
  hasMinWidth?: boolean
}

interface ModalProviderProps {
  children: ReactNode
}

interface ModalProviderValue {
  openModal: (
    modal: ReactNode,
    containerProps?: Partial<ModalContainerProps>
  ) => void
  closeModal: () => void
}

const context = createContext({} as ModalProviderValue)

const ModalProvider: FC<ModalProviderProps> = ({ children }) => {
  const [modal, setModal] = useState<ReactNode | null>(null)
  const [containerProps, setContainerProps] = useState<
    Partial<ModalContainerProps>
  >({})

  const openModal = useCallback(
    (node: ReactNode, containerProps: Partial<ModalContainerProps> = {}) => {
      setContainerProps(containerProps)
      setModal(node)
    },
    [setModal, setContainerProps]
  )

  const closeModal = useCallback(() => {
    setModal(null)
    setContainerProps({})
  }, [setModal, setContainerProps])

  const value = useMemo(
    () => ({ openModal, closeModal }) as ModalProviderValue,
    [openModal, closeModal]
  )

  return (
    <context.Provider value={value}>
      {children}
      <ModalContainer open={modal !== null} {...containerProps}>
        {modal}
      </ModalContainer>
    </context.Provider>
  )
}

export default ModalProvider

export const useModal = () => useContext(context)
