import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#a5bff3",
      dark: "#121212"
    },
    secondary: {
      main: "#f0b52e",
    },
  },
});
