export interface MultilingualContent {
  simplifiedChinese: string;
  traditionalChinese: string;
  english: string;
  japanese: string;
  vietnamese: string;
}

export class Language {
  static SIMPLIFIED_CHINESE = "simplifiedChinese";
  static TRADITIONAL_CHINESE = "traditionalChinese";
  static ENGLISH = "english";
  static JAPANESE = "japanese";
  static VIETNAMESE = "vietnamese";
}

export const languageOptions = [
  { name: "simplifiedChinese", displayText: "简体中文" },
  { name: "traditionalChinese", displayText: "繁體中文" },
  { name: "english", displayText: "English" },
  { name: "japanese", displayText: "日本語" },
  { name: "vietnamese", displayText: "Tiếng Việt" },
];
