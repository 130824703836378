import { Box, Fade, SxProps } from "@mui/material";
import { FC, useEffect, useMemo, useRef, useState } from "react";

interface ImageSliderProps {
  images: Array<string>;
  changeFrequency: number;
  animationSpeed: number;
  imageSx?: SxProps;
  containerSx?: SxProps;
}

const ImageSlider: FC<ImageSliderProps> = ({
  images,
  changeFrequency,
  animationSpeed,
  imageSx = {},
  containerSx = {},
}) => {
  const [displayImage, setDisplayImage] = useState(0);
  const timer = useRef<any>(null);

  useEffect(() => {
    timer.current = setInterval(() => {
      setDisplayImage((prev) => {
        prev++;
        if (prev === images.length) {
          prev = 0;
        }
        return prev;
      });
    }, changeFrequency);

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  return (
    <>
      <Box
        sx={{
          ...containerSx,
          // display: "flex",
          overflow: "hidden",
          position: "relative",
        }}
      >
        {images.map((image, index) => (
          <Fade
            in={index === displayImage}
            timeout={animationSpeed}
            key={image}
          >
            <Box
              sx={{
                ...imageSx,
                flexShrink: "0",
                position: "absolute",

                backgroundImage: `url(${image})`,
                backgroundSize: "cover",
                backgroundPosition: "center center",
              }}
            />
          </Fade>
        ))}
      </Box>
    </>
  );
};

export default ImageSlider;
