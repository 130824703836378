import { FC, ReactNode, createContext, useContext, useMemo } from "react";
import { MultilingualContent } from "../models/content";

interface ContentProviderProps {
  children: ReactNode;
}

interface ContentProviderValue {
  title: {
    companyDescription: MultilingualContent;
  };
  content: {
    companyDescription: MultilingualContent;
  };
}

const context = createContext({} as ContentProviderValue);

const ContentProvider: FC<ContentProviderProps> = ({ children }) => {
  const value = useMemo(
    () =>
      ({
        title: {
          companyDescription: {
            simplifiedChinese: "公司介绍",
            traditionalChinese: "公司介紹",
            english: "About Us",
            japanese: "会社概要",
            vietnamese: "Mô tả công ty",
          },
        },
        content: {
          companyDescription: {
            simplifiedChinese:
              "QCW咨询有限公司于2018年在新西兰最大城市奥克兰成立。我们的主要业务范围包括新西兰商业法律会计事务资讯，市场开发，国际劳务输出，人力资源管理，商业移民投资留学等。目前涉及国家除新西兰以外还有澳大利亚，中国，日本，越南等大部分东南亚国家。我们的团队拥有多年的国际商业经验，为您在商业计划，市场开发，投资等领域保驾护航。",
            traditionalChinese:
              "QCW諮詢有限公司於2018年在紐西蘭最大城市奧克蘭成立。我們的主要業務範圍包括紐西蘭商業法律會計事務資訊，市場開發，國際勞務輸出，人力資源管理，商業移民投資留學等。目前涉及國家除紐西蘭以外還有澳大利亞，中國，日本，越南等大部分東南亞國家。我們的團隊擁有多年的國際商業經驗，爲您在商業計劃，市場開髮，投資等領域保駕護航。",
            english:
              "QCW Consultancy Ltd was founded in Auckland New Zealand 2018. Our business involves serval business categories including commercial law and accounting consultancy, New Zealand marketing development, international labour and human resource management, business investment, immigration and overseas studies. Our team will use our delighted international business experience to serve you with business plans, investment and marketing in our partner countries including New Zealand, Australia, China, Japan, Vietnam and most South Asian countries. ",
            japanese:
              "QCW Consultancy Ltd は、2018 年にニュージーランドのオークランドで設立されました。当社の事業は、商法および会計コンサルティング、ニュージーランドのマーケティング開発、国際労働および人材管理、事業投資、移民、海外研究など、さまざまな事業分野に及びます。当社のチームは、ニュージーランド、オーストラリア、中国、日本、ベトナム、およびほとんどの南アジア諸国を含むパートナー国での事業計画、投資、マーケティングの支援に、国際ビジネスの経験を活かして対応します。",
            vietnamese:
              "Công ty TNHH Tư vấn QCW được thành lập vào năm 2018 tại Auckland, thành phố lớn nhất New Zealand. Phạm vi kinh doanh chính của chúng tôi bao gồm thông tin kế toán pháp lý doanh nghiệp New Zealand, xuất khẩu lao động quốc tế, quản lý nguồn nhân lực, nhập cư kinh doanh, đầu tư và du học, v.v. Ngoài New Zealand, các nước tham gia hiện nay còn có Australia, Trung Quốc, Nhật Bản, Việt Nam và hầu hết các nước Đông Nam Á khác. Đội ngũ của chúng tôi có nhiều năm kinh nghiệm kinh doanh quốc tế để bảo vệ bạn trong việc lập kế hoạch kinh doanh, phát triển thị trường, đầu tư và các lĩnh vực khác.",
          },
        },
      } as ContentProviderValue),
    []
  );

  return <context.Provider value={value}>{children}</context.Provider>;
};

export default ContentProvider;

export const useContent = () => useContext(context);
