import { Box, Typography } from "@mui/material";
import { useMemo } from "react";
import CircleIcon from "@mui/icons-material/Circle";

import oversearStudy from "../assets/cases/oversea-study.jpg";
import tutorial from "../assets/cases/tutorial.jpg";
import interview from "../assets/cases/interview.jpg";
import shanghai from "../assets/cases/shanghai.jpg";
import hr from "../assets/cases/viet-hr.jpg";
import { useMultilingual } from "../providers/MultilingualProvider";
import { Language } from "../models/content";

const SuccessfulCasesModal = () => {
  const { language } = useMultilingual();
  const languageIndex = useMemo(() => {
    if ([Language.ENGLISH, Language.VIETNAMESE].includes(language as string)) {
      return 0;
    } else if (
      [Language.SIMPLIFIED_CHINESE, Language.TRADITIONAL_CHINESE].includes(
        language as string
      )
    ) {
      return 1;
    } else {
      return 2;
    }
  }, [language]);

  const cases = useMemo(() => {
    return [
      {
        text: [
          "Shanghai. CHINA international student commitment",
          "上海，中國 留學生説明會",
          "中国・上海留学生説明会",
        ],
        image: oversearStudy,
      },
      {
        text: [
          "Labour training school in Vietnam",
          "越南勞務培訓學校",
          "ベトナム労働者訓練学校",
        ],
        image: tutorial,
      },
      {
        text: [
          "Panel Beater, Automotive Machinic interview for New Zealand Workshop",
          "越南噴漆工，汽車裝備工面試，成功申請在紐西蘭工作",
          "ベトナムでスプレー塗装職人および自動車機器の作業員と面接をして、ニュージーランドで仕事に就きました。",
        ],
        image: interview,
      },
      {
        text: [
          "Visiting the New Zealand consulate in Shanghai CHINA",
          "訪問紐西蘭駐上海領事館",
          "上海のニュージーランド総領事館を訪問",
        ],
        image: shanghai,
      },
      {
        text: [
          "Visiting the human resource company in Hanoi Vietnam",
          "與越南人力資源公司合作",
          "ベトナム現地の人材送出し機関を訪問",
        ],
        image: hr,
      },
    ];
  }, []);

  return (
    <Box>
      {cases.map((c) => {
        return (
          <Box sx={{ paddingY: 0.5, display: "flex" }}>
            <Box>
              <img src={c.image} alt="" width={"200px"} />
            </Box>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography sx={{ pl: 2 }}>{c.text[languageIndex]}</Typography>
              </Box>
              {/* {c.text.map((content, index) => {
                return (
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography sx={{ pl: 2 }}>{content}</Typography>
                  </Box>
                );
              })} */}
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

export default SuccessfulCasesModal;
