import { Box } from "@mui/material";
import { FC, ReactNode } from "react";

interface PageProps {
  children: ReactNode;
}

const Page: FC<PageProps> = ({ children }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#121212",
        color: "white",
        minHeight: "100vh",
        width: "100vw",
      }}
    >
      {children}
    </Box>
  );
};

export default Page;
