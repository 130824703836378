import { ReactNode } from "react/index";
import { FC } from "react";
import { Box, IconButton, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useModal } from "../providers/ModalProvider";

export interface ModalContainerProps {
  children: ReactNode;
  open: boolean;
  hasMinWidth?: boolean;
}

const ModalContainer: FC<ModalContainerProps> = ({
  children,
  open,
  hasMinWidth = false,
}) => {
  const { closeModal } = useModal();

  return (
    <Modal open={open}>
      <Box
        sx={{
          "&>div": {
            position: "absolute",
            top: "50%",
            left: "50%",
            p: 2,
            display: "flex",
            flexDirection: "column",
            // prettier-ignore
            ...(hasMinWidth
              ? {
                maxWidth: {
                  xs: "90%",
                  md: "50%"
                }
              } : {
                width: {
                  xs: "90%",
                  md: "50%"
                }
              }),
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            border: "3px solid",
            borderColor: "primary.main",
            borderRadius: 2,
            "& div.MuiBox-root": {
              marginY: 0,
            },
            overflow: "hidden",
          },
        }}
      >
        <Box>
          {children}
          <Box
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
            }}
          >
            <IconButton onClick={() => closeModal()}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalContainer;
