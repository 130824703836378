import { Navigate, Route, Routes } from "react-router-dom";
import AboutUsPage from "../pages/AboutUs";
import { routes } from "../routes";
import SuccessfulCasesPage from "../pages/SuccessfulCases";

const Router = () => {
  return (
    <Routes>
      <Route path={routes.ABOUT_US} element={<AboutUsPage />} />
      <Route path={routes.SUCCESSFUL_CASES} element={<SuccessfulCasesPage />} />
      <Route path={"*"} element={<Navigate to={routes.ABOUT_US} />} />
    </Routes>
  );
};

export default Router;
