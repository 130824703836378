import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme";
import { BrowserRouter } from "react-router-dom";
import ContentProvider from "./providers/ContentProvider";
import MultilingualProvider from "./providers/MultilingualProvider";
import ModalProvider from "./providers/ModalProvider";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <ContentProvider>
          <MultilingualProvider>
            <ModalProvider>
              <App />
            </ModalProvider>
          </MultilingualProvider>
        </ContentProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
);
