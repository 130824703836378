import { useCallback, useMemo, useEffect } from "react";
import { useMultilingual } from "../../providers/MultilingualProvider";
import { useContent } from "../../providers/ContentProvider";
import {
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  Typography,
  Grid,
} from "@mui/material";
import Page from "../../containers/Page";

import { Language, languageOptions } from "../../models/content";
import logo from "../../assets/logo.png";
import ImageSlider from "../../components/ImageSlider";

import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

import slide1 from "../../assets/imageslides/b1c57815ad7c0b9205c44470c0ca651.png";
import slide2 from "../../assets/imageslides/89ff8b70724f66883ad4295e25a88dc.png";
import slide3 from "../../assets/imageslides/9ce3f08a5b75c54e3b5817b335c0b7d.png";
import slide4 from "../../assets/imageslides/a90ee024cb8e865729806cd258e03a6.png";
import aboutUsBackground from "../../assets/imageslides/e43212f9a850f2eee3f21a3a1eab7bb.jpg";
import contactImage from "../../assets/contact.jpg";
import { useModal } from "../../providers/ModalProvider";
import SuccessfulCasesModal from "../../modals/SuccessfulCases";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

import labour from "../../assets/services/labour.jpg";
import nzMarket from "../../assets/services/nz-market.jpg";
import lawSupport from "../../assets/services/internation-law.jpg";
import imigration from "../../assets/services/imgra0-consu.jpg";
import overseaStudy from "../../assets/services/oversear-study.png";
import hr from "../../assets/services/hr.jpg";

interface ContactMethod {
  fName: string;
  lName: string;
  email: string;
  phone: string;
}

interface Service {
  title: Array<string>;
  content: Array<string>;
  image?: string;
}

const AboutUsPageBuilder = () => {
  const { language, setLanguage } = useMultilingual();
  const { content } = useContent();

  const { openModal } = useModal();

  useEffect(() => {
    document.title = "QCW Consultancy";
  }, []);

  const changeLanguageHandler = useCallback(
    (language: Language) => {
      setLanguage(language);
    },
    [setLanguage]
  );

  const companyDescription = useMemo(() => {
    return Object.entries(content.companyDescription).find(([key, value]) => {
      return key === (language as string);
    })![1];
  }, [language, content]);

  const navigate = useNavigate();

  const showSuccessfulCasesHandler = useCallback(() => {
    navigate(routes.SUCCESSFUL_CASES);
  }, [navigate]);

  const languageIndex = useMemo(() => {
    if ([Language.ENGLISH, Language.VIETNAMESE].includes(language as string)) {
      return 0;
    } else if (
      [Language.SIMPLIFIED_CHINESE, Language.TRADITIONAL_CHINESE].includes(
        language as string
      )
    ) {
      return 1;
    } else {
      return 2;
    }
  }, [language]);

  const ourServices = useMemo(() => {
    return [
      {
        title: [
          "International labour opportunities",
          "國際勞務輸出",
          "外国労働者の送出し",
        ],
        content: [
          "We are hiring from multiple nations and supplied to Japan, Taiwan, and New Zealand markets to work. Provide interviews in local labour countries and support work visa applications.",
          "多國招聘合適員工，輸送至日本，台灣，紐西蘭等勞務市場。協助面試，辦理簽證",
          "我々は、様々な国の人材を、日本、台湾、ニュージーランド市場に供給しています。面接や労働ビザの申請手続きもサポートします。",
        ],
        image: labour,
      },

      {
        title: [
          "New Zealand business consulting (law/accounting)",
          "紐西蘭市場開發，法律 會計咨詢",
          "NZ事業コンサルティング(法律/会計)",
        ],
        content: [
          "We have more than 20 years of local management background. We also have law and accounting experience. We are confident that we can provide fully viewed suggestions on the development of New Zealand marketing services",
          "我們擁有超過二十年的紐西蘭本土管理經驗，加之我們的法律和會計背景。可以提供多方位，全方面紐西蘭市場開發，研究的建議。",
          "我々は、ニュージーランドで20年以上の人材管理経験と、法律及び会計の経験も持っており、知識も豊富です。そのため、ニュージーランド市場の開発や調査に関する、多角的で包括的な提案を、自信を持って提案できます。",
        ],
        image: nzMarket,
      },

      {
        title: [
          "International Commercial Meditation ",
          "國際商業法律援助",
          "国際商業仲裁をサポート",
        ],
        content: [
          "We do international commercial mediation according to our multi-national law experiences. ",
          "擁有多國法律經驗，我們提供國際商業調解",
          "我々は、複数国の法律経験をもとに、国際商業仲裁をサポートします。",
        ],
        image: lawSupport,
      },

      {
        title: [
          "Immigration Consulting to New Zealand and Australia",
          "澳大利亞 紐西蘭移民事務咨詢",
          "移民サポート(NZ＆AUS) ",
        ],
        content: [
          "Australia and New Zealand certified immigration advisor. We provide fully categorized immigration suggestions. Your case is only an application for us. However, it could change your whole life. We will be responsible for your trust. ",
          "澳洲，紐西蘭持牌移民律師坐鎮，多年移民經驗為客戶提供包括各類移民方案。成功開啓您人生新的征程。您的選擇對於我們來説只是一個申請，但是對於您來説，卻會改變您家庭的一生，所以我們會認真對待每一份信任。",
          "我々は、オーストラリアとニュージーランドの認定移民アドバイザーです。長年、移民に関する様々なサポートをしてきました。我々にとって、お客様の依頼は、単なる依頼ではありません。ご本人様、そしてご家族の人生を大きく左右する可能性があると、我々は受け止めています。そのため、我々は責任を持って業務に取り組みます。",
        ],
        image: imigration,
      },

      {
        title: ["Overseas studies", "國際留學", "留学サポート"],
        content: [
          "we have different university options in New Zealand and Australia. A good plan is the first step of your life. We can help on your journey.",
          "我們可以提供多個學校作爲選擇。紐西蘭和澳大利亞擁有豐富的教育資源。各所大學名列世界大學前列。教育業作爲國家的支柱產業為您未來的人生保駕護航。",
          "我々は、ニュージーランドとオーストラリアの複数の学校を紹介します。両国は、豊富な教育カリキュラムを持ち、世界の大学ランキングでも上位に位置しています。教育は、国家の重要な基幹産業でもあります。我々はお客様の未来を最大限サポートします。",
        ],
        image: overseaStudy,
      },

      {
        title: ["Human resource management", "人力資源管理", "人材管理"],
        content: [
          "The principle of human resource management is that the right people are in the right position to maximize the benefits for the business.",
          "公司管理重在經營，經營生意重在管人。合適的人才在合適的崗位才能發揮有效的價值。",
          "人材管理の原則は、適切な人材を適切なポジションに配置することで、ビジネスの利益を最大化することです。",
        ],
        image: hr,
      },
    ] as Array<Service>;
  }, []);

  const contactMethods = useMemo(() => {
    return [
      {
        fName: 'Yohsuke "Bruce"',
        lName: "Aoki",
        email: "aoki@bs-ac.com",
        phone: "+64 28 8512 8046",
      },
      {
        fName: "FName",
        lName: "LName",
        email: "someone@gmail.com",
        phone: "021-123-1234",
      },
    ] as Array<ContactMethod>;
  }, []);

  return (
    <Page>
      {/* HEADER */}
      <Box
        sx={{
          paddingX: 3,
          paddingY: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          backgroundColor: "#121212",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <img src={logo} alt={""} width={"50px"} />
          <Typography variant="h6">QCW Consultancy</Typography>
        </Box>
        <Box>
          <Select
            variant="standard"
            size="small"
            value={language as string}
            onChange={(value: SelectChangeEvent) =>
              changeLanguageHandler(value.target.value)
            }
            sx={{
              color: "white",
            }}
          >
            {languageOptions.map(({ name, displayText }) => (
              <MenuItem key={name} value={name}>
                {displayText}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      {/* BIG IMAGES */}
      <Box
        sx={{
          pt: 5,
          width: "100vw",
        }}
      >
        <ImageSlider
          images={[slide1, slide2, slide3, slide4]}
          changeFrequency={3000}
          animationSpeed={1500}
          imageSx={{
            width: "100vw",
            height: {
              xs: "30vh",
              sm: "50vh",
              md: "100vh",
            },
          }}
          containerSx={{
            width: "100vw",
            height: {
              xs: "30vh",
              sm: "50vh",
              md: "100vh",
            },
          }}
        />
      </Box>

      {/* OUR SERVICES */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: 4,
        }}
      >
        <Box
          sx={{
            pt: 2,
            maxWidth: "1200px",
            paddingX: {
              xs: 2,
              md: 0,
            },
          }}
        >
          <Box
            sx={{
              display: {
                xs: "block",
                sm: "flex",
              },
              alignItems: "center",
              justifyContent: "space-between",
              p: 4,
              pb: 0,
            }}
          >
            <Typography variant="h3">Our Services</Typography>
            <Typography
              onClick={() => showSuccessfulCasesHandler()}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
            >
              See our successful cases
            </Typography>
          </Box>
          <Grid container>
            {ourServices.map(({ title, content, image }, index) => (
              <Grid
                item
                xs={6}
                sx={{
                  height: "600px",
                  p: {
                    xs: 1,
                    sm: 4,
                  },
                  pb: {
                    xs: 0,
                    sm: 0,
                  },
                  pt: {
                    xs: 1,
                    sm: 4,
                  },
                }}
              >
                <Box
                  sx={{
                    p: {
                      xs: 1,
                      md: 2,
                    },
                    backgroundColor: "primary.main",
                    borderRadius: 2,
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{ color: "primary.dark", fontWeight: "bold" }}
                    >
                      {title[languageIndex]}
                    </Typography>
                    <Typography sx={{ color: "primary.dark" }}>
                      {content[languageIndex]}
                    </Typography>
                    {/* {content.map((service) => (
                      <Typography sx={{ color: "primary.dark" }}>
                        {service}
                      </Typography>
                    ))} */}
                  </Box>
                  {image && (
                    <Box
                      sx={{
                        flex: 1,
                        backgroundColor: "red",
                        backgroundImage: `url(${image})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center center",
                      }}
                    ></Box>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>

      {/* COMPANY DESCRIPTION */}
      <Box
        sx={{
          mt: 3,
          p: {
            xs: 2,
            sm: 5,
            md: 20,
          },
          display: "flex",
          justifyContent: "center",
          backgroundImage: `url(${aboutUsBackground})`,
          backgroundSize: "cover",
          backgroundPosition: "center center",
          mb: 10,
        }}
      >
        <Box
          sx={{
            p: 5,
            width: "900px",
            borderRadius: 2,
            color: "#0a214e",
            backgroundColor: "rgba(255, 255, 255, .7)",
          }}
        >
          <Typography>{companyDescription}</Typography>
        </Box>
      </Box>

      {/* CONTACT */}
      <Box
        sx={{
          // backgroundColor: "primary.main",
          // color: "primary.dark",
          p: 3,
          // display: "flex",
          // justifyContent: "center",
        }}
      >
        <Box
          sx={{
            maxWidth: "1200px",
            margin: "0 auto",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pb: 10,
          }}
        >
          <Typography variant="h4" sx={{ pb: 3 }}>
            Contact Us
          </Typography>
          <Typography variant="body1">info@bs-ac.com</Typography>
          <Typography variant="h6"></Typography>
          <Box
            sx={{
              display: "flex",
            }}
          >
            {/* <img src={contactImage} alt="" width={"300px"} /> */}
            {/* {contactMethods.map(({ fName, lName, email, phone }) => (
              <Box
                sx={{
                  paddingX: 15,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <Typography sx={{ pt: 1 }}>
                  {fName} {lName}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center", pt: 1 }}>
                  <PhoneIcon />
                  <Typography sx={{ pl: 1 }}>{phone}</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: "center", pt: 1 }}>
                  <EmailIcon />
                  <Typography sx={{ pl: 1 }}>{email}</Typography>
                </Box>
              </Box>
            ))} */}
          </Box>
        </Box>
      </Box>
    </Page>
  );
};

export default AboutUsPageBuilder;
