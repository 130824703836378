import {
  FC,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Language, languageOptions } from "../models/content";

interface MultilingualProviderProps {
  children: ReactNode;
}

interface MultilingualProviderValue {
  language: Language;
  setLanguage: (language: Language) => void;
  getDisplayableText: () => { name: string; displayText: string };
}

const context = createContext({} as MultilingualProviderValue);

const MultilingualProvider: FC<MultilingualProviderProps> = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = useState<Language>(
    Language.ENGLISH
  );

  const getDisplayableText = useCallback(() => {
    return languageOptions.find(({ name }) => {
      return name === currentLanguage;
    });
  }, [currentLanguage]);

  const value = useMemo(
    () =>
      ({
        language: currentLanguage,
        setLanguage: setCurrentLanguage,
        getDisplayableText,
      } as MultilingualProviderValue),
    [currentLanguage, setCurrentLanguage, getDisplayableText]
  );

  return <context.Provider value={value}>{children}</context.Provider>;
};

export default MultilingualProvider;

export const useMultilingual = () => useContext(context);
