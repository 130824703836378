import {
  Box,
  Typography,
  Select,
  SelectChangeEvent,
  MenuItem,
} from "@mui/material";
import Page from "../../containers/Page";
import SuccessfulCasesModal from "../../modals/SuccessfulCases";
import { Language, languageOptions } from "../../models/content";
import logo from "../../assets/logo.png";
import { useMultilingual } from "../../providers/MultilingualProvider";
import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";

const SuccessfulCasesPage = () => {
  const { language, setLanguage } = useMultilingual();

  const changeLanguageHandler = useCallback(
    (language: Language) => {
      setLanguage(language);
    },
    [setLanguage]
  );

  const languageIndex = useMemo(() => {
    if ([Language.ENGLISH, Language.VIETNAMESE].includes(language as string)) {
      return 0;
    } else if (
      [Language.SIMPLIFIED_CHINESE, Language.TRADITIONAL_CHINESE].includes(
        language as string
      )
    ) {
      return 1;
    } else {
      return 2;
    }
  }, [language]);

  const navigate = useNavigate();

  return (
    <Page>
      <Box
        sx={{
          paddingX: 3,
          paddingY: 1,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          backgroundColor: "#121212",
        }}
      >
        <Box
          sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
          onClick={() => navigate(routes.ABOUT_US)}
        >
          <img src={logo} alt={""} width={"50px"} />
          <Typography variant="h6">QCW Consultancy</Typography>
        </Box>
        <Box>
          <Select
            variant="standard"
            size="small"
            value={language as string}
            onChange={(value: SelectChangeEvent) =>
              changeLanguageHandler(value.target.value)
            }
            sx={{
              color: "white",
            }}
          >
            {languageOptions.map(({ name, displayText }) => (
              <MenuItem key={name} value={name}>
                {displayText}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>
      <Box>
        <Box
          sx={{
            maxWidth: "1200px",
            margin: "0 auto",
            mt: 15,
            p: 1,
          }}
        >
          <SuccessfulCasesModal />
        </Box>
      </Box>
    </Page>
  );
};

export default SuccessfulCasesPage;
